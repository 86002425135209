nav {
  background-color:black;
  width:80%;
  font-family: 'Chilanka';
}

.navbar-brand {
  color: white !important;
  text-decoration: none !important;
}

.nav-font{
  font-family: 'Chilanka';
}

.head-font {
  font-family: 'Potta One';
}

nav a img {
  display: inline-block;
  margin: 10px 50px 10px 10px;

}

#bdNav ul li a {
  display: inline-block;
  padding: 5px 10px 10px 10px;
  color: white;
  text-decoration: none;
  
}

.topnav a {
  display: inline-block;
  padding: 5px 10px 10px 10px;
  color: white;
  text-decoration: none;
  font-family: 'Chilanka';
  font-size: large;
}

.topnav a.active {
  color: greenyellow !important;
  font-family: 'Chilanka';
  
}

a:hover {
  color: greenyellow !important;
  
}

.nav-link {
  color: white !important;
  font-family: 'Chilanka';
}

.nav-link.active {
  color: greenyellow !important;
  font-family: 'Chilanka';
  
}

.secnav {
  background-color: white;
}


