.head-font-home {
  font-family: 'Potta One';
  font-size: 2.3vw;
}


.section0-img {
  position: relative;
  text-align: center;
   overflow:hidden;

}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
/* .bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
} */

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 94%;
  left: 70%;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: grey
  
}

.centered2 {
  background-color: transparent;
  color: black;
  position: absolute;
  top: 3%;
  left: 75%;
  text-align: left;
}

.centered3 {
  position: absolute;
  top: 3%;
  left: 0%;
  text-align: left;
  font-style: italic;
}

.leftlogo {
  position: absolute;
  top: 65%;
  left:  5%;
  text-align: left;
  font-style: italic;
}

.leftlogo2 {
  position: absolute;
  top: 85%;
  left:  5%;
  text-align: left;
  font-style: italic;
}

.handimg {
  background-color: transparent;
  z-index: 0px;
  position: absolute;
  top: 0%;
  left:  80%;
  text-align: left;
  font-style: italic;
}

.bagimg {
  position: absolute;
  top: 30%;
  left:  80%;
  text-align: left;
  font-style: italic;
}

.centerimg {
  width: 100%;
  object-fit: cover;
  object-position: 10% 20%;
  margin-top: -10vw;
  
}