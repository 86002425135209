.my-tab .tab-pane{ 
  border:solid 1px blue;  
  border-top: 0; 
  background-color:#F7EFC6;

}

ul>li {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: large;
}

.nTab {
  display: flex;
    align-items: center;
    max-width: 320px;
    font-size: 14px;
    width: 33%;
    border-bottom: 2px solid #A3B3B3;
    padding: 40px 10px;
    transition: border-bottom .3s ease;
    cursor: pointer;
   
}

#myTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: transparent;
    margin: 0 auto;
    
}

.news-font{
  font-family: 'PT Serif';
}